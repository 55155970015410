.custom-table {
    background-color: black;
    color: white;
    font-size: 16px;
    border-collapse: collapse;
    width: 95%;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid white;
    text-align: center;
    padding: 8px;
  }
  
  .custom-table th {
    background-color: black;
    color: white;
  }
  